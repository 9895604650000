import { Box, Checkbox } from '@mui/material';
import React, { Component } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default class AlgoSelect extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            algorithms : {}
        };
    }
    
    componentDidMount() {
        console.log("Loading algorithms...");
      fetch(this.props.api + "/algorithms")
      .then(response => {
        return response.json();
      })
      .then(data => {
        let algo = data;
        console.log("Loaded algorithms: ", algo);
        this.setState({
          algorithms: algo
        });
      })
      .catch(error => {
        console.log(error);
      });
    }
    
    render() {
      return(
        <Box sx={{textAlign: 'center', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-evenly'}}>
            <FormGroup sx={{flexDirection: 'row'}}>
            {
                Object.entries(this.state.algorithms).map( 
                ([key, desc]) => <React.Fragment key={key}>
                    <Box sx={{width: '450px', textAlign:'left'}}><FormControlLabel value={key} key={key} control={
                    <Checkbox checked={(this.props.algos || []).includes(key)} onChange={ (event) => {
                        if (event.target.checked) {
                            this.props.onChange((this.props.algos || []).concat(key));
                        } else {
                            this.props.onChange((this.props.algos || []).filter(x => x != key));
                        }
                    }}/>
                } label={desc} /></Box>
                </React.Fragment>)
            }
            </FormGroup>
        </Box>
      );
    }
    
}