import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Typography from '@mui/material/Typography';
import { Stack } from "@mui/material";
import { Popper } from '@mui/base/Popper';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';

export default class ForBinDocument extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            openedArea: null,
            lastChange: new Date(),
            detailsAnchor: null
        };
        this.keydownHandler = this.keydownHandler.bind(this);
        this.onAreaOpenChanged = this.onAreaOpenChanged.bind(this);
    }

    keydownHandler = (event) => {
        if (event.target == document.body && this.state.openedArea) {
            // No input
            if (event.code == "KeyR") {
                this.props.onChange({
                    orig: this.state.openedArea,
                    newX: this.state.openedArea?.x,
                    newY: this.state.openedArea?.y,
                    newW: this.state.openedArea?.h,
                    newH: this.state.openedArea?.w
                });
                this.forceRefresh();
            } else {
                let x = 0;
                let y = 0;
                if (event.code == "ArrowLeft" || event.code == "KeyA") x = -1;
                if (event.code == "ArrowRight" || event.code == "KeyD") x = +1;
                if (event.code == "ArrowUp" || event.code == "KeyW") y = -1;
                if (event.code == "ArrowDown" || event.code == "KeyS") y = +1;

                if (event.shiftKey) {
                    x *= 10;
                    y *= 10;
                }

                if (x != 0 || y != 0) {
                    this.props.onChange({
                        orig: this.state.openedArea,
                        newX: this.state.openedArea?.x + x,
                        newY: this.state.openedArea?.y + y,
                        newW: this.state.openedArea?.w,
                        newH: this.state.openedArea?.h
                    });
                    this.forceRefresh();
                }
            }
        }
    }

    forceRefresh() {
        let id = this.state.openedArea?.id;
        this.setState({lastChange: new Date()},
            () => { this.setState({openedArea: this.props.computed.rects.find(x => x.id == id)}, this.onAreaOpenChanged);  });
    }

    componentDidMount() {
        document.addEventListener("keydown", this.keydownHandler);
    }
    
    componentWillUnmount() {
        document.removeEventListener("keydown", this.keydownHandler);
    }

    onAreaOpenChanged() {
        this.props.onAreaOpenChanged(!!this.state.openedArea);
    }
    
    
    render() {
        const fontProps = {
            fontFamily: "Verdana",
            fontSize: 4,
            fill: "black"
        }
        let all = this.props.allArea?.h * this.props.allArea?.w;
        let my = this.state.openedArea?.w * this.state.openedArea?.h;
        let used = (my / all) * 100;
        return(<div className="document">
                    <svg viewBox={'0 0 ' + this.props.allArea.w + ' ' + this.props.allArea.h}>
                        <pattern id="diagonalHatch" width="10" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
                            <rect width="10" height="10" className='bbox'></rect>
                            <line x1="0" y1="0" x2="0" y2="10" style={{"stroke":"#F008", "strokeWidth":"1"}} />
                        </pattern>
                        {this.props.computed && this.props.computed.aw &&
                            <rect x={this.props.computed.ab.x} y={this.props.computed.ab.y || 0} width={this.props.computed.aw || 0} height={this.props.computed.ah || 0} className="allbox"></rect>
                        }
                        {this.props.computed && this.props.computed.bb.w &&
                            <rect x={this.props.computed.bb.x} y={this.props.computed.bb.y || 0} width={(this.props.computed.bb.w + this.props.computed.sp) || 0} height={(this.props.computed.bb.h + this.props.computed.sp) || 0} className="bbox"></rect>
                        }
                        {this.props.computed && this.props.computed.aw &&
                            <rect x={this.props.computed.ab.x} y={this.props.computed.ab.y || 0} width={this.props.computed.aw || 0} height={this.props.computed.ah || 0} className="allbox2"></rect>
                        }
                        {this.props.computed &&
                            this.props.computed.rects.map((r, i) => <g key={i}>
                                <rect x={r.x} y={r.y} width={r.w} height={r.h} className={"rect" + (this.state.openedArea?.id == r.id ? ' active' : '')} onClick={
                                    () => { if (this.state.openedArea?.id == r.id) this.setState({openedArea: null}, this.onAreaOpenChanged); else this.setState({openedArea: r}, this.onAreaOpenChanged); }
                                }></rect>
                                <text {...fontProps} x={r.x + 2} y={r.y + 7}>
                                    {r.t1}
                                </text>
                                <text {...fontProps} x={r.x + 2} y={r.y + 14}>
                                    {r.t2}
                                </text>
                            </g>)
                        }
                        {this.props.allArea.w &&
                            <rect width={this.props.allArea.w} height={this.props.allArea.h} className="area"></rect>
                        }
                    </svg>

                    <Popper placement="bottom-start" open={!!this.state.openedArea} anchorEl={this.props.detailsAnchor}>
                        <div
                            style={{
                                padding: '0.5rem 1rem',
                                border: '1px solid',
                                borderColor: 'rgba(0,0,0,0.2)',
                                margin: '0.5rem',
                                borderRadius: '0.5rem',
                                boxShadow: '0 2px 8px 0 rgba(0,0,0,0.1)',
                                width: 500,
                                background: 'white',
                                maxHeight: 'calc(100vh - 60px)',
                                overflow: 'auto',
                                position: 'relative'
                            }}
                        >
                            <Stack direction="row">
                                <Typography variant="h5" gutterBottom><InfoIcon fontSize='small' />&nbsp;Object details</Typography>
                                <Box sx={{flex: 1, textAlign: 'right'}}>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => { this.setState({ openedArea: null }, this.onAreaOpenChanged); }}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                        >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Stack>
                            <Box>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Identifier:</TableCell>
                                                <TableCell colSpan={2}>{this.state.openedArea?.t1}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>X:</TableCell>
                                                <TableCell colSpan={2}>
                                                    <TextField label="X" value={this.state.openedArea?.x} type="number" onChange={
                                                        (event) => {
                                                            this.props.onChange({
                                                                orig: this.state.openedArea,
                                                                newX: Number(event.target.value),
                                                                newY: this.state.openedArea?.y,
                                                                newW: this.state.openedArea?.w,
                                                                newH: this.state.openedArea?.h
                                                            });
                                                            this.forceRefresh();
                                                        }
                                                    }
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position='end'>units</InputAdornment>,
                                                    }} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Y:</TableCell>
                                                <TableCell colSpan={2}>
                                                    <TextField label="Y" value={this.state.openedArea?.y} type="number" onChange={
                                                        (event) => {
                                                            this.props.onChange({
                                                                orig: this.state.openedArea,
                                                                newY: Number(event.target.value),
                                                                newX: this.state.openedArea?.x,
                                                                newW: this.state.openedArea?.w,
                                                                newH: this.state.openedArea?.h
                                                            });
                                                            this.forceRefresh();
                                                        }
                                                    }
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position='end'>units</InputAdornment>,
                                                    }} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Width:</TableCell>
                                                <TableCell>{this.state.openedArea?.w} units</TableCell>
                                                <TableCell rowSpan={2}>
                                                <IconButton
                                                    aria-label="swap width and height"
                                                    onClick={() => {
                                                        this.props.onChange({
                                                            orig: this.state.openedArea,
                                                            newX: this.state.openedArea?.x,
                                                            newY: this.state.openedArea?.y,
                                                            newW: this.state.openedArea?.h,
                                                            newH: this.state.openedArea?.w
                                                        });
                                                        this.forceRefresh();
                                                    }}
                                                    >
                                                    <SwapVertIcon />
                                                </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Height:</TableCell>
                                                <TableCell>{this.state.openedArea?.h} units</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Object size:</TableCell>
                                                <TableCell colSpan={2}>{my} units</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Cut-out area usage:</TableCell>
                                                <TableCell colSpan={2}>{used.toFixed(2)} %</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Price:</TableCell>
                                                <TableCell colSpan={2}>{this.state.openedArea?.t2}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </div>
                    </Popper>
                </div>)
    }
        
}