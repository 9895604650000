import React, { Component } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CropIcon from '@mui/icons-material/Crop';

export default class RectComp extends Component {
    
    constructor(props) {
        super(props);

        /*this.state = {
            id: this.props.id,
            cnt: this.props.cnt,
            w: this.props.w,
            h: this.props.h
        };*/
        this.handleChangeH = this.handleChangeH.bind(this);
        this.handleChangeW = this.handleChangeW.bind(this);
        this.handleChangeCnt = this.handleChangeCnt.bind(this);
        this.handleChangeId = this.handleChangeId.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }
    
    /*componentDidUpdate() {
        this.props.change(this.state.id, this.state.w, this.state.h, this.state.cnt);
    }*/
    
    handleChangeId = (e) => {
        //this.setState({ id: e.target.value });
        this.props.onChange({id: e.target.value, w: this.props.w, h: this.props.h, cnt: this.props.cnt});
    };
    handleChangeW = (e) => {
        //this.setState({ w: e.target.value });
        this.props.onChange({id: this.props.id, w: e.target.value, h: this.props.h, cnt: this.props.cnt});
    };
    handleChangeH = (e) => {
        //this.setState({ h: e.target.value });
        this.props.onChange({id: this.props.id, w: this.props.w, h: e.target.value, cnt: this.props.cnt});
    };
    handleChangeCnt = (e) => {
        //this.setState({ cnt: e.target.value });
        this.props.onChange({id: this.props.id, w: this.props.w, h: this.props.h, cnt: e.target.value});
    };
    
    handleRemove()
    {
        this.props.onRemove();
    }
    
    render() {
      return(
        <Card sx={{marginTop: 1}}>
            <CardContent>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    { !!this.props.isTechnicalDrawing && <AttachFileIcon /> }
                    { !this.props.isTechnicalDrawing && <CropIcon /> }
                    <TextField sx={{background: !!this.props.isTechnicalDrawing && '#EEE'}} label="Identifier" value={this.props.id} type="text" onChange={this.handleChangeId} inputProps={{ readOnly:!!this.props.isTechnicalDrawing}} />
                    <TextField label="Count" value={this.props.cnt} type="number" onChange={this.handleChangeCnt}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">pieces</InputAdornment>,
                          }} />
                    <TextField sx={{background: !!this.props.isTechnicalDrawing && '#EEE'}} label="Width" value={this.props.w} type="number" onChange={this.handleChangeW}  inputProps={{ readOnly:!!this.props.isTechnicalDrawing}}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">units</InputAdornment>,
                          }} />
                    <TextField sx={{background: !!this.props.isTechnicalDrawing && '#EEE'}} label="Height" value={this.props.h} type="number" onChange={this.handleChangeH}  inputProps={{ readOnly:!!this.props.isTechnicalDrawing}}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">units</InputAdornment>,
                          }} />
                    <Button sx={{float: 'right'}} onClick={this.handleRemove} startIcon={<DeleteIcon />}>Remove</Button>
                </Stack>
            </CardContent>
        </Card>
        );
      }
}