import React, { Component } from 'react';

export default class ExampleBox extends Component {
    
    constructor(props) {
        super(props)
    }
    
    render() {
        const fontProps = {
            fontFamily: "Verdana",
            fontSize: 10,
            fill: "black"
        }
      return(
        <svg viewBox="0 0 360 280">
            <pattern id="diagonalHatch" width="10" height="10" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="10" style={{"stroke":"#F008", "strokeWidth":"1"}} />
            </pattern>
            <rect x={20} y={20} width={320} height={240} className="demo doc" />
            <rect x={40} y={40} width={280} height={200} className="demo area" />
            <rect x={40} y={40} width={260} height={160} className="demo margin" />
            <rect x={60} y={60} width={100} height={50} className="demo box" />
            <rect x={180} y={60} width={100} height={50} className="demo box" />
            <rect x={60} y={130} width={100} height={50} className="demo box" />
            <rect x={180} y={130} width={100} height={50} className="demo box" />
            <polygon points="40 200, 300 200, 300 40, 320, 40, 320 240, 40 240" className="demo unused" />
            <text {...fontProps} x={20} y={15}>Working area width ({this.props.docWidth})</text>
            <text {...fontProps} x={-260} y={15} transform="rotate(-90)">Working area height ({this.props.docHeight})</text>
            <text {...fontProps} x={40} y={35}>Border ({this.props.border})</text>
            <text {...fontProps} x={130} y={124}>Spacing ({this.props.spacing})</text>
            <text {...fontProps} x={60} y={54}>Spacing ({this.props.spacing})</text>
            <text {...fontProps} x={-180} y={54} transform="rotate(-90)">Spacing ({this.props.spacing})</text>
            <text {...fontProps} x={140} y={225}>Unused space</text>
        </svg>
        );
      }
}