import { Drawer, Stack, TableRow, Table, TableContainer, TableCell, TableHead, TableBody, Checkbox, Tooltip, } from "@mui/material";
import React, { Component, createRef } from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import Fab from '@mui/material/Fab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ReactCompList from './RectCompList'
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ForBinDocument from "./ForBinDocument";
import TextField from '@mui/material/TextField';
import { Space } from 'react-zoomable-ui';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceBox from "./PriceBox";
import ForBinResolver from "./ForBinResolver";
import EditIcon from '@mui/icons-material/Edit';
import ExampleBox from "./ExampleBox";
import WidgetsIcon from '@mui/icons-material/Widgets';
import CropFreeIcon from '@mui/icons-material/CropFree';
import TerminalIcon from '@mui/icons-material/Terminal';
import AlgoSelect from "./AlgoSelect";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Ruler from "./Ruler";
import Logo from '../logo.svg';
import { styled } from '@mui/material/styles';

export default class RectComp extends Component {
    
    dataResolver = new ForBinResolver;

    constructor(props) {
        super(props);
        
        this.state = {
            drawerOpened: false,
            drawerMaximized: false,
            drawerPage: 0,
            showAgain: false,

            pricingOpened: false,

            rects: [],
            solvedRects: [],
            isChanged: false,
            isComputing: false,

            docWidth: 300,
            docHeight: 1000,
            spacing: 10,
            border: 0,

            allArea: {w: 0, h: 0, x: 0, y: 0},
            computedData: null,
            boundingBox: {w: 0, h: 0, x: 0, y: 0},
            allBox: {w: 0, h: 0, x: 0, y: 0},
            selectedAlgos: [/*"GuillotineBruteforce10YMin",*/ "ColumnRowOptimizer", "GuillotineYMin", "GuillotineArea"],
            bestAlgo: "",
            price: 0.01,

            camera: null,
            pricingAnchor: null,
            detailsAnchor: null,

            viewPort: null,
            cursorX: 0,
            cursorY: 0,
            cursorPercX: 0,
            cursorPercY: 0,
            cursorPosX: 0,
            cursorPosY: 0,

            areaOpened: false,
            closedTutorial: false,
            rulerActive: true,

            showHint: true,
        };

        this.onChange = this.onChange.bind(this);
        this.solve = this.solve.bind(this);
        this.recenter = this.recenter.bind(this);
        this.mouseUpdate = this.mouseUpdate.bind(this);

        this.docRef = React.createRef();
    }

    onChange(rects) {
        this.setState({
            rects: rects,
            isChanged: true
        });
    }

    componentDidMount() {
        this.solve();
    }

    solve(){
        this.setState({
            isChanged: false,
            isComputing: true
        }, async () => {

            var d = await this.dataResolver.solveAndFindBest(
                this.props.api,
                this.props.axios,
                this.state.docWidth,
                this.state.docHeight,
                this.state.spacing,
                this.state.border,
                this.state.rects,
                this.state.price,
                this.state.selectedAlgos
            );
            if (d) {
                this.setState(d, this.recenter);
            } else {
                this.setState({showSolvingError: true});
            }
            this.setState({
                isComputing: false,
                pricingOpened: !!this.state.rects.length,
                showAgain: !!this.state.rects.length
            });
        });
    }    
    recenter()
    {
        this.state.camera && this.state.camera.recenter(window.innerWidth / 2, (window.innerHeight + this.state.docHeight) / 2, 0.35);
    }

    mouseUpdate(event)
    {
        //this.setState({cursorX: event.clientX, cursorY: event.clientY});
        let pos = this.state.viewPort?.translateClientXYCoordinatesToVirtualSpace(event.clientX, event.clientY);
        let factor = this.state.docWidth / this.state.viewPort?.containerWidth;
        let percX = (pos.x / (this.state.docWidth) * factor) || 0;
        let percY = (pos.y / (this.state.docHeight) * factor) || 0;
        let valX = Number(percX * this.state.docWidth).toFixed(0);
        let valY = Number(percY * this.state.docHeight).toFixed(0);
        if (event.clientX != this.state.cursorX || event.clientY != this.state.cursorY)
            this.setState(
                {
                    cursorX: event.clientX,
                    cursorY: event.clientY,
                    cursorPercX: percX,
                    cursorPercY: percY,
                    cursorPosX: valX,
                    cursorPosY: valY
                }
            );
    }

    render() {

        return(
            <div onMouseMove={this.mouseUpdate}>

                <span className="details-anchor" ref={(elem) => { if (!this.state.detailsAnchor) this.setState({detailsAnchor: elem}) }}></span>
                {this.state.rulerActive && <>
                    <Ruler orientation="vertical" viewPort={this.state.viewPort} docWidth={this.state.docWidth} docHeight={this.state.docHeight} />
                    <Ruler orientation="horizontal" viewPort={this.state.viewPort} docWidth={this.state.docWidth} docHeight={this.state.docHeight} />
                    </>
                }
                <span className="ruler-corner">
                    <Checkbox checked={this.state.rulerActive} onChange={(ev)=> { this.setState({rulerActive: ev.target.checked}) }}></Checkbox>
                </span>

                {this.state.rulerActive && <>
                    <div className="guideline horizontal" style={{ top: this.state.cursorY }}><span className="pos x">{this.state.cursorPosY}</span></div>
                    <div className="guideline vertical" style={{ left: this.state.cursorX }}><span className="pos y">{this.state.cursorPosX}</span></div>
                </>
                }

                <img src={Logo} className="logo" width={200} />

                { !this.state.closedTutorial && this.state.areaOpened && <div className="tutorial">
                    <div className="help">
                        <span className="key a">←<span>A</span></span>
                        <span className="key w">↑<span>W</span></span>
                        <span className="key d">→<span>D</span></span>
                        <span className="key s">↓<span>S</span></span>
                        <span className="move">Move object</span>
                        <span className="key r">R</span>
                        <span className="key shift">⇑ Shift</span>
                        <span className="shift">Move by 10</span>
                        <span className="rotate">Rotate</span>
                    </div>
                    <span className="close" onClick={() => { this.setState({closedTutorial: true}); }}>×</span>
                </div>}

                <Space
                    onCreate={viewPort => {
                        this.setState({camera: viewPort.camera}, this.recenter);
                        this.recenter();
                    }}
                    onUpdated={ (props) => { if(props.zoomFactor < 0.1) props.camera.recenter(props.centerX, props.centerY, 0.1); this.setState({ viewPort : props }); }}
                    style={{zIndex: -1}}
                    
                >
                    <Box className="document" ref={this.docRef}>
                        <ForBinDocument
                            detailsAnchor={this.state.detailsAnchor}
                            allArea={this.state.allArea}
                            computed={this.state.computedData}
                            onAreaOpenChanged={opened => this.setState({areaOpened: opened})}
                            onChange={(args) => {
                                let mutated = structuredClone(this.state.computedData.solvedRects);
                                let elem = mutated.find(x => x.id == args.orig.id);
                                if (elem) {
                                    elem.x = args.newX - this.state.spacing - this.state.border;
                                    elem.y = args.newY - this.state.spacing - this.state.border;
                                    elem.w = args.newW + this.state.spacing;
                                    elem.h = args.newH + this.state.spacing;
                                }
                                var d = this.dataResolver.manualSolve(
                                    this.state.docWidth,
                                    this.state.docHeight,
                                    this.state.spacing,
                                    this.state.border,
                                    mutated,
                                    this.state.price,
                                );
                                if (d) {
                                    this.setState(d);
                                    this.setState({showAgain: false});
                                }
                            }}
                        ></ForBinDocument>
                    </Box>
                </Space>

                <Drawer
                    PaperProps={{
                        sx: { 
                            margin: this.state.drawerMaximized ? "50px" : "0px"
                         },
                    }}
                    anchor="bottom"
                    open={this.state.drawerOpened}
                    onClose={() => this.setState({drawerOpened: false})}
                >
                    <Paper sx={{overflow: 'hidden', padding: '0px 20px 20px', height: this.state.drawerMaximized ? 'calc(100vh - 120px)' : '400px', background: '#F8F8F8'}}>

                        <Stack direction="row">
                            <Tabs
                                value={this.state.drawerPage}
                                onChange={(e, i) => this.setState({drawerPage: i })}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons={true}
                                allowScrollButtonsMobile={true}
                            >
                                <Tab label="Input areas" icon={<WidgetsIcon />} iconPosition="start" />
                                <Tab label="Space settings" icon={<CropFreeIcon />} iconPosition="start" />
                                <Tab label="Price settings" icon={<AttachMoneyIcon />} iconPosition="start" />
                                <Tab label="Algorithms" icon={<TerminalIcon />} iconPosition="start" />
                            </Tabs>
                            <Box sx={{flex: 1, textAlign: 'right', marginTop: 2}}>
                                <IconButton onClick={() => this.setState({drawerMaximized: !this.state.drawerMaximized})}>
                                    { !this.state.drawerMaximized && <OpenInFullIcon /> }
                                    { this.state.drawerMaximized && <CloseFullscreenIcon /> }
                                </IconButton>
                                <IconButton onClick={() => this.setState({drawerOpened: false})}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                    
                        <Box overflow="auto" sx={{height: 'calc(100% - 50px)'}}>
                            { this.state.drawerPage == 0 && <Box value={this.state.drawerPage} index={0}>
                                <ReactCompList rects={this.state.rects} onChange={this.onChange} />
                            </Box>}
                            { this.state.drawerPage == 1 && <Box value={this.state.drawerPage} index={1}>
                                <Box sx={{marginTop: 4, marginBottom: 2, textAlign: 'center'}}>
                                    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                        <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                                            <TextField label="Working area width" value={this.state.docWidth} type="number"
                                                onChange={(e) => { this.setState({ docWidth: Number(e.target.value), isChanged: true }) }} />
                                            <TextField label="Working area height" value={this.state.docHeight} type="number"
                                                onChange={(e) => { this.setState({ docHeight: Number(e.target.value), isChanged: true }) }} />
                                            <TextField label="Spacing" value={this.state.spacing} type="number"
                                                onChange={(e) => { this.setState({ spacing: Number(e.target.value), isChanged: true }) }} />
                                            <TextField label="Border" value={this.state.border} type="number"
                                                onChange={(e) => { this.setState({ border: Number(e.target.value), isChanged: true }) }} />
                                        </Stack>
                                        <Box sx={{width: 320, height: 240, display: 'inline-block', marginTop: 2}}>
                                            <ExampleBox docWidth={this.state.docWidth} docHeight={this.state.docHeight} border={this.state.border} spacing={this.state.spacing} />
                                        </Box>
                                    </Stack>
                                </Box>
                            </Box>}
                            { this.state.drawerPage == 2 && <Box value={this.state.drawerPage} index={2}>
                                <Box sx={{marginTop: 4, marginBottom: 2, textAlign: 'center'}}>
                                    <TextField label="Base price (for 1 unit²)" value={this.state.price} type="number"
                                        inputProps={{
                                            step: "0.01", min: "0"
                                        }}
                                        onChange={(e) => { this.setState({ price: Number(e.target.value), isChanged: true }) }} />
                                </Box>
                            </Box>}
                            { this.state.drawerPage == 3 && <Box value={this.state.drawerPage} index={3}>
                                <Box sx={{marginTop: 4, marginBottom: 2, textAlign: 'center'}}>
                                    <AlgoSelect api={this.props.api} algos={this.state.selectedAlgos} onChange={(algos) => { this.setState({selectedAlgos: algos, isChanged: true}); }} />
                                </Box>
                            </Box>}
                        </Box>
                    </Paper>
                </Drawer>

                <span className="pricing-anchor" ref={(elem) => { if (!this.state.pricingAnchor) this.setState({pricingAnchor: elem}) }}></span>
                <PriceBox open={!!this.state.pricingAnchor && !!this.state.rects.length && this.state.pricingOpened}
                    pricingAnchor={this.state.pricingAnchor} onClose={() => {this.setState({pricingOpened: false})}}
                    totalArea={this.state.computedData?.totalArea} usedArea={this.state.computedData?.usedArea} wasted={this.state.computedData?.wasted} price={this.state.price}
                    priceStats={this.state.computedData?.priceStats} />
                
                <Tooltip
                    arrow
                    open={this.state.showHint}
                    title={<h2 style={{padding: "0px 10px"}}>Add technical drawing or custom region</h2>}
                    disableHoverListener={true}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                maxWidth: 'none',
                            },
                        }
                    }}
                    slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [-150, 0],
                              },
                            },
                          ],
                        },
                      }}
                >
                    <Fab
                        onClick={() => this.setState({drawerOpened: true, showHint: false})}
                        label="Settings"
                        color="primary"
                        sx={{position: 'absolute', bottom: 16, right: 16}}
                    >
                        <EditIcon />
                    </Fab>
                </Tooltip>

                { !this.state.showAgain && !this.state.isComputing && !this.state.isChanged && this.state.rects?.length && <Fab
                    onClick={this.solve}
                    label="Recompute"
                    color="success"
                    sx={{position: 'absolute', bottom: 16, right: 88}}
                >
                    <AutorenewIcon />
                </Fab> }

                { !!this.state.rects.length && <Fab
                    onClick={() => this.setState({pricingOpened: !this.state.pricingOpened})}
                    label="Settings"
                    color="secondary"
                    sx={{position: 'absolute', bottom: 88, right: 16}}
                >
                    <AttachMoneyIcon />
                </Fab> }

                { this.state.isChanged &&
                <Box sx={{ width:'100%', position:'fixed', textAlign:'center', marginTop: 5 }}><Fab
                    label="Recompute"
                    size="large" variant="extended"
                    color="warning"
                    onClick={this.solve}
                >
                    <AutorenewIcon />&nbsp;Recompute
                </Fab></Box>
                }
                { this.state.showAgain && !this.state.isChanged && !this.state.isComputing &&
                <Box sx={{ width:'100%', position:'fixed', textAlign:'center', marginTop: 5 }}><Fab
                    label="Try another solution"
                    size="large" variant="extended"
                    color="success"
                    onClick={this.solve}
                >
                    <AutorenewIcon />&nbsp;Another solution
                </Fab>&nbsp;
                <Fab
                    label="Do not try another solution"
                    size="small"
                    color="success"
                    onClick={() => { this.setState({ showAgain: false }) }}
                >
                    <CloseIcon />
                </Fab>
                </Box>
                }

                <Dialog
                    open={!!this.state.showSolvingError}
                    onClose={() => { this.setState({showSolvingError: false}) }}
                >
                    <DialogTitle>
                        No solution found
                    </DialogTitle>
                    <DialogContent>
                        There was a problem while trying to arange the objects using selected Bin Packing Algorithms.
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => { this.setState({showSolvingError: false}) }}>OK</Button>
                    </DialogActions>
                </Dialog>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={!!this.state.isComputing}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }

}