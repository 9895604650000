import logo from './logo.svg';
import './App.css';
import ForBin from './components/ForBin.jsx'
import env from "react-dotenv";
import axios from 'axios';

//const axios = require('axios').default;
const api = (env && (env.API_URL || env.REACT_APP_API_URL)) || "http://localhost:7042";

function App() {
  return <ForBin axios={axios} api={api} />;
}

export default App;
